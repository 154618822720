function Footer() {
  return (
    <div>
      <footer className="py-3 my-4">
        <p className="text-center text-body-secondary">© 2023 Guilherme Oszika Duarte</p>
      </footer>
    </div>
  );
}
export default Footer;
